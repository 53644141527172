import { NgModule } from '@angular/core'
import { MatTableModule } from '@angular/material/table'

import { SharedModule } from '../../../../../shared/modules/shared.module'
import { TableComponent } from '../../../../../shared/modules/table/table.component'
import { InvoiceHistoryComponent } from './invoice-history.component'

@NgModule({
  declarations: [InvoiceHistoryComponent],
  imports: [MatTableModule, SharedModule, TableComponent],
  exports: [InvoiceHistoryComponent]
})
export class InvoiceHistoryModule {}
