import { NgModule } from '@angular/core'
import { MatTableModule } from '@angular/material/table'

import { SharedModule } from '../../../../../shared/modules/shared.module'
import { TableComponent } from '../../../../../shared/modules/table/table.component'
import { ReminderHistoryComponent } from './reminder-history.component'

@NgModule({
  declarations: [ReminderHistoryComponent],
  imports: [MatTableModule, SharedModule, TableComponent],
  exports: [ReminderHistoryComponent]
})
export class ReminderHistoryModule {}
