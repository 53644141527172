import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { trigger } from '@angular/animations'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import moment from 'moment'
import { finalize } from 'rxjs/operators'

import { EconomyTabService } from '../../../../../core/api'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { PaymentTypes } from '../../../../../core/enums/economy/payment-types'
import { UserResponse } from '../../../../../core/interfaces/user/user-response'
import { UtilsService } from '../../../../../core/services'
import { BaseComponent } from '../../../../../shared/components/base/base.component'
import { DashboardState } from '../../../states/dashboard.state'
import { PriceCategoryResponse } from './interfaces/price-category-response'
import { GroupType } from '../../../../../core/enums/organization/group-type'
import { Constants } from '../../../../../constants/constants'
import { OverlayAnimations } from '../../../../../shared/components/overlay/animations'
import { WINDOW } from '../../../../../core/services/window.service'
import { PriceCategoryType } from '../enums/price-category-type'
import { Organization } from '../../../../../core/interfaces/organization/organization'

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.scss'],
  host: { '[@newInvoiceAnimation]': '' },
  animations: [trigger('newInvoiceAnimation', OverlayAnimations.detailsPopup)],
  standalone: false
})
export class NewInvoiceComponent extends BaseComponent implements OnInit {
  @SelectSnapshot(DashboardState.organization) organization: Organization

  @Input() user: Partial<UserResponse>
  @Input() userPaymentId: number
  @Input() paymentType: PaymentTypes
  @Input() paymentName: string
  @Input() dueDate: string
  @Input() netAmount: number
  @Input() note: string

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  loading: boolean = false
  isLoadingPriceCategories: boolean = false
  priceCategories: PriceCategoryResponse[]
  customPriceCategory: PriceCategoryResponse | undefined
  customPrice: number
  isSettingCustomPrice: boolean = false
  minAge: number = Constants.minAge
  maxAge: number = Constants.maxAge
  invoiceForm: FormGroup
  today = moment().format(DateFormat.YYYYMMDD)

  get Constants() {
    return Constants
  }

  get GroupType() {
    return GroupType
  }

  get PaymentTypes() {
    return PaymentTypes
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private formBuilder: FormBuilder,
    private economyTabService: EconomyTabService,
    private utils: UtilsService
  ) {
    super()
  }

  ngOnInit() {
    this.initForm()

    if (this.paymentType === PaymentTypes.MembershipFee) {
      this.isLoadingPriceCategories = true
      this.economyTabService
        .getPriceCategories(this.userPaymentId)
        .pipe(finalize(() => (this.isLoadingPriceCategories = false)))
        .subscribe((categories) => {
          this.priceCategories = categories
            .filter((category) => category.type === PriceCategoryType.Default)
            .map((category) => ({
              ...category,
              net_amount: this.utils.getNOK(category.net_amount),
              total_fee: this.utils.getNOK(category.total_fee),
              gross_amount: this.utils.getNOK(category.gross_amount)
            }))

          this.customPriceCategory = categories.find((category) => category.type === PriceCategoryType.Custom)
          if (this.customPriceCategory || this.priceCategories.length > 1) {
            this.toggleCustomPrice(true)
          }
        })
    }
  }

  private initForm(): void {
    const invoiceForm: any = {
      bankAccountNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(Constants.bankAccountNumberLength),
          Validators.maxLength(Constants.bankAccountNumberLength)
        ]
      ],
      dueDate: [moment(new Date(this.dueDate)).format(DateFormat.YYYYMMDD), Validators.required],
      note: [this.note]
    }

    if (this.paymentType !== PaymentTypes.MembershipFee) {
      invoiceForm.netAmount = [this.utils.getNOK(this.netAmount), Validators.required]
    }

    this.invoiceForm = this.formBuilder.group(invoiceForm)
  }

  toggleCustomPrice(isSettingCustomPrice: boolean): void {
    if (isSettingCustomPrice) {
      this.invoiceForm.addControl(
        'customPrice',
        new FormControl(
          this.customPriceCategory ? this.utils.getNOK(this.customPriceCategory.net_amount) : null,
          Validators.required
        )
      )
    } else {
      this.invoiceForm.removeControl('customPrice')
    }

    this.isSettingCustomPrice = isSettingCustomPrice
  }

  generateInvoice(): void {
    if (this.invoiceForm.valid) {
      this.loading = true
      const payload = {
        due_date: this.invoiceForm.value.dueDate,
        bank_account: this.invoiceForm.value.bankAccountNumber,
        note: this.invoiceForm.value.note,
        net_amount: this.utils.getOre(
          this.paymentType !== PaymentTypes.MembershipFee
            ? this.invoiceForm.value.netAmount
            : this.isSettingCustomPrice
              ? this.invoiceForm.value.customPrice
              : this.priceCategories[0].net_amount
        )
      }

      this.economyTabService
        .generateInvoice(this.userPaymentId, payload)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe((response) => {
          this.window.open(response.file, '_blank')
          this.close.emit(true)
        })
    }
  }

  onClose(): void {
    this.close.emit(false)
  }
}
