import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { trigger } from '@angular/animations'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { finalize } from 'rxjs/operators'

import { EconomyTabService } from '../../../../../core/api'
import { Columns } from '../../../../../core/enums/table/columns'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { PaidStatus } from '../enums/paid-status'
import { Table } from '../../../../../core/classes/table/table'
import { UserResponse } from '../../../../../core/interfaces/user/user-response'
import { InvoiceHistoryResponse } from './interfaces/invoice-history-response'
import { OverlayAnimations } from '../../../../../shared/components/overlay/animations'
import { WINDOW } from '../../../../../core/services/window.service'
import { PaymentTypes } from '../../../../../core/enums/economy/payment-types'
import { EconomyTabState, UserPermissions } from '../states/economy-tab.state'

@Component({
  selector: 'app-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.scss'],
  host: { '[@invoiceHistoryAnimation]': '' },
  animations: [trigger('invoiceHistoryAnimation', OverlayAnimations.detailsPopup)],
  standalone: false
})
export class InvoiceHistoryComponent implements OnInit {
  @SelectSnapshot(EconomyTabState.userEconomyPermissions) userEconomyPermissions: UserPermissions

  @Input() user: Partial<UserResponse>
  @Input() paymentName: string
  @Input() userPaymentId: number
  @Input() paymentType: PaymentTypes
  @Input() paidStatus: PaidStatus

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  table: Table<InvoiceHistoryResponse> = new Table()

  get Columns() {
    return Columns
  }

  get PaymentTypes() {
    return PaymentTypes
  }

  get PaidStatus() {
    return PaidStatus
  }

  get DateFormat() {
    return DateFormat
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private economyTabService: EconomyTabService
  ) {}

  ngOnInit() {
    this.table.columns = [Columns.InvoiceNumber, Columns.GenerationDate]
    this.getInvoiceHistory()
  }

  private getInvoiceHistory(): void {
    this.table.loading = true
    this.economyTabService
      .getInvoiceHistory(this.userPaymentId)
      .pipe(finalize(() => (this.table.loading = false)))
      .subscribe((response) => (this.table.data = response))
  }

  openInvoice(invoice: InvoiceHistoryResponse): void {
    this.economyTabService.getInvoiceUrl(invoice.id).subscribe((response) => this.window.open(response.link, '_blank'))
  }

  onClose(shouldGenerateNewInvoice: boolean): void {
    this.close.emit(shouldGenerateNewInvoice)
  }
}
