<app-popup [loading]="loading" [isDismissible]="!loading" (popupClose)="onClose()">
  <h3 class="popup__header">
    <span>{{ 'economy.new_reminder_for' | translate }} {{ user.first_name }} {{ user.last_name }}</span>
    <div class="popup__header-button-wrapper">
      <div class="popup__header-buttons">
        <button mat-icon-button (click)="onClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </h3>
  <div class="popup__body ns-form ns-light default-text-color">
    <p
      class="ns-text"
      [innerHTML]="
        'economy.new_reminder_description' | translate: { firstName: user.first_name, lastName: user.last_name }
      "
    ></p>

    <app-expand-y *ngIf="smsStatus && smsStatus !== SMSStatus.NotAllowed" class="ns-notify-by-sms">
      <div class="ns-checkbox-field">
        <mat-checkbox [formControl]="notifyBySMSField">
          <div class="ns-label-header">
            <span class="bold">{{ 'sms.notify_via_sms' | translate }}</span>
          </div>
          <p>{{ 'sms.notify_via_sms_description' | translate }}</p>
        </mat-checkbox>
      </div>

      <app-expand-y *ngIf="notifyBySMSField.value" class="cost-wrapper">
        <div class="cost ns-text small">
          <span>{{ 'sms.cost' | translate }}:</span>
          <span>{{ cost }} {{ (cost === 1 ? 'sms.credit' : 'sms.credits') | translate }}</span>
        </div>
      </app-expand-y>

      <p *ngIf="smsStatus === SMSStatus.NotEnoughCredits" class="hint ns-text extra-small">
        {{ 'sms.not_enough_credits_hint' | translate }}
      </p>
    </app-expand-y>

    <mat-form-field>
      <mat-label>{{ 'miscellaneous.message' | translate }}</mat-label>
      <textarea
        matInput
        [formControl]="messageField"
        [maxlength]="Constants.maxCharactersInPushNotification"
        rows="3"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
      ></textarea>
      <mat-hint>
        {{ 'miscellaneous.max_number_characters' | translate: { number: Constants.maxCharactersInPushNotification } }}
      </mat-hint>
    </mat-form-field>

    <div class="popup__button-wrapper">
      <app-button theme="transparent" size="small" (click)="onClose()">
        {{ 'action.cancel' | translate }}
      </app-button>
      <app-button size="small" (click)="sendReminder()">
        {{ 'action.send' | translate }}
      </app-button>
    </div>
  </div>
</app-popup>

<app-modal *ngIf="notEnoughCreditsModal.isOpen" (modalClose)="notEnoughCreditsModal.close()">
  <button class="modal__close-button" mat-icon-button (click)="notEnoughCreditsModal.close()">
    <mat-icon>close</mat-icon>
  </button>
  <h3 class="modal__header">{{ 'sms.not_enough_credits' | translate }}</h3>
  <div class="modal__body ns-text default-text-color">{{ 'sms.not_enough_credits_description' | translate }}</div>
</app-modal>
