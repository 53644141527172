import { NgModule } from '@angular/core'

import { SharedModule } from '../../../../../shared/modules/shared.module'
import { NewInvoiceComponent } from './new-invoice.component'
import { DisableValueChangeOnWheelDirective } from '../../../../../shared/directives/disable-value-change-on-wheel.directive'

@NgModule({
  declarations: [NewInvoiceComponent],
  imports: [SharedModule, DisableValueChangeOnWheelDirective],
  exports: [NewInvoiceComponent]
})
export class NewInvoiceModule {}
