<app-overlay appFloatingElement (close)="onClose(false)">
  <div class="overlay__buttons">
    <app-button theme="transparent" class="back-button" (click)="onClose(false)">
      {{ 'action.back' | translate }}
    </app-button>
    <app-button
      *ngIf="
        (paymentType === PaymentTypes.RegularPayment ||
          paymentType === PaymentTypes.EventPayment ||
          (paymentType === PaymentTypes.MembershipFee && userEconomyPermissions.membershipFee.canEdit) ||
          (paymentType === PaymentTypes.TrainingFee && userEconomyPermissions.trainingFee.canEdit)) &&
        (paidStatus === PaidStatus.NotPaid || paidStatus === PaidStatus.Optional)
      "
      theme="dark"
      class="next-button"
      (click)="onClose(true)"
    >
      {{ 'economy.generate_new_invoice' | translate }}
    </app-button>
  </div>

  <div class="overlay__header">
    <h2>{{ 'economy.invoice_history_for' | translate }} {{ user.first_name }} {{ user.last_name }}</h2>
    <p class="sub-header ns-text small">{{ paymentName }}</p>
  </div>

  <div class="overlay__body">
    <app-table
      [data]="table.data"
      [columns]="table.columns"
      [loading]="table.loading"
      [paginator]="false"
      [search]="false"
    >
      <ng-container [matColumnDef]="Columns.InvoiceNumber">
        <mat-header-cell *matHeaderCellDef>{{ 'table.invoice_number' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="clickable">
          <button class="ns-clickable-cell-button" appBlurOnClick (click)="openInvoice(row)">
            <span>{{ row.invoice_number }}</span>
            <i class="material-icons-round">open_in_new</i>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.GenerationDate">
        <mat-header-cell *matHeaderCellDef>{{ 'table.generation_date' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <p class="ns-date">{{ row.generated_at | date : DateFormat.shortDateTime }}</p>
        </mat-cell>
      </ng-container>
    </app-table>
  </div>
</app-overlay>
